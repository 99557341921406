.index-params {
    display: grid;
    font-family: "Graphikmedium";
    grid-template-columns: 100px 1fr 1fr 1fr;
    gap: 60px;
    padding: 65px 20px 0px 20px;
    position: sticky;
    top: 0;
    background-color: var(--background-color);
    margin-bottom: 18px;
}
.text-image-grid.opacity-0 {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}
.text-image-grid.opacity-100 {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
}
.info {
    padding: 0px 40px 0px 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.index {
    position: sticky;
    top: 99px;
    background-color: var(--background-color);
}
.index-wrapper {
    margin-bottom: -10px;
    padding: 0;
}
.index-item {
    display: grid;
    align-items: end;
    grid-template-columns: 100px 1fr 1fr 1fr;
    gap: 40px;
    padding: 0 20px 0 20px;
}
.index .index-item {
    border-top: solid 1px var(--primary-color);
    height: 40px;
    margin-bottom: 0;
    transition: all 0.5 ease-in-out;
}
.index-item-year {
    display: grid;
    justify-items: end;
}
.index-items:has(.text-image-grid) img.index-preview-pic {
    filter: opacity(1);
}
img.index-preview-pic {
    filter: opacity(0.7);
}
.index-items:hover img.index-preview-pic {
    filter: opacity(1);
    transition: 0.2s all ease-in-out;
}
.index-item-arrow,
.index-item-name,
.index-item-project,
.index-item-year {
    height: 30px;
}
.index-item > :nth-child(3),
.index-item > :nth-child(4) {
    border-left: solid 1px;
    padding-left: 20px;
    height: 40px;
}
.index-item > :nth-child(5) {
    height: 40px;
}
.index-preview-container {
    height: 40px;
    width: 70px;
    position: absolute;
    left: 90px;
    z-index: -1;
}
img.index-preview-pic {
    height: 41px;
    width: 100%;
    object-fit: cover;
    opacity: 1;
}
.index-item h3 {
    display: grid;
    align-items: end;
    padding-top: 5px;
}
ul.index-items {
    margin: -15px 0 0 0;
    padding: 0;
}
.images {
    padding-left: 40px;
}
.index-item-pics {
    width: 160px;
    aspect-ratio: 16/9;
    object-fit: cover;
}
.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
}
.img-module {
    margin-top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
}
.index-item-pics img {
    width: 100%;
    height: 100%;
}
.text-image-grid {
    display: grid;
    padding: 10px 10px 10px 10px;
    grid-gap: 40px;
    transition: all 0.2s ease-in-out;
}
.text-image-grid.active {
    grid-template-columns: 100px 1fr 1fr 1fr;
}
h6.sticky-text {
    background-color: var(--background-color);
    color: var(--primary-color);
    border-top: solid 1px;
    font-family: "Graphikregular", Fallback, sans-serif;
    margin-top: -40px;
    padding: 10px;
    position: fixed;
    width: 100%;
}
.image-grid {
    grid-column: 3/5;
}
.image-grid img {
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
}
.image-grid img:hover {
    opacity: 1;
}
.text-grid {
    grid-column: 1/3;
    margin-left: 140px;
}
.image-container {
    position: absolute;
    right: 0;
    top: 0;
}
.image-container img,
.image-container video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.image-container video {
    margin-top: -5px;
}
.LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
}
.is-visible {
    opacity: 1;
}

@media screen and (max-width: 1200px) {
    .image-grid img {
        opacity: 1;
    }
    .index .index-item {
        height: auto;
        gap: 0;
    }
    .index-item {
        grid-template-columns: 110px 1.5fr 1.5fr 1fr;
    }
    .index-params {
        gap: 20px;
        z-index: 1;
        grid-template-columns: 110px 1.5fr 1.5fr 1fr;
    }
    .text-grid {
        margin-left: 0;
    }
    .text-image-grid {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        padding: 15px 10px;
    }
    .index-item h3 {
        font-family: "Graphik";
        display: grid;
        align-items: end;
        padding-top: 0;
    }
    .index-item-pics {
        width: 140px;
        height: 80px;
        object-fit: cover;
    }
    .index-preview-container {
        width: 70px;
        left: 60px;
    }
    img.index-preview-pic {
        height: 40px;
    }
    .image-container {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img.blurred-image,
    video.blurred-image {
        display: block;
        width: 200%;
        height: 100%;
        position: absolute;
        filter: blur(21px);
        z-index: -1;
    }
    img.fixed-image,
    video.fixed-image {
        height: auto;
        width: 100%;
    }
    h6.sticky-text {
        bottom: -10px;
    }

    @media screen and (max-width: 800px) {
        .production-section,
        .residences-section {
            padding-top: 39px;
        }
        .index-preview-container {
            position: absolute;
            left: 0;
            top: 1px;
            z-index: 0;
        }
        img.index-preview-pic {
            height: 150px;
            width: 80px;
            object-fit: cover;
            opacity: 1;
        }

        .index-item-arrow,
        .index-item-name,
        .index-item-project,
        .index-item-year {
            height: auto;
        }
        ul.index-items {
            margin: 0;
            padding: 0;
        }
        .index-item > :nth-child(3),
        .index-item > :nth-child(4),
        .index-item > :nth-child(5) {
            border-left: none;
            padding-left: 0;
            height: auto;
        }
        .index {
            position: relative;
            top: 0;
        }
        .image-grid {
            align-content: flex-start;
        }
        .text-image-grid {
            padding: 10px 10px 10px 10px;
        }
        .index-item {
            display: grid;
            grid-template-columns: 1fr;
            padding: 20px 20px 0 20px;
            margin: 0;
        }
        .index-item h3 {
            justify-content: center;
        }
        .index-item-year {
            justify-items: start;
        }
        .index-params {
            display: none;
        }
        .text-image-grid {
            padding-top: 20px;
        }
        ul.index-items {
            margin: 0 0 -15px 0;
        }
    }
}
