.navbar {
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    position: fixed;
    width: 100vw;
    background-color: var(--background-color);
    z-index: 2;
    border-bottom: 1px solid var(--primary-color);
}
.nav-lan {
    color: var(--primary-color);
    position: fixed;
    right: 60px;
    top: -1px;
}
.nav-lan button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    cursor: none !important;
}
.nav-lan button.mobile {
    margin-top: 5.5px;
    font-size: 1.2rem;
}
.nav-logo {
    margin: 0;
    list-style: none;
    padding: 0;
    position: fixed;
    left: 20px;
    top: -5px;
    font-family: "Graphikmedium", Fallback, sans-serif;
}
.nav-menu {
    min-width: 850px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    list-style: none;
    width: 80vw;
    margin-top: 7px;
}
ul.nav-menu {
    padding: 0;
}
.nav-item {
    height: 39px;
    width: 100%;
}
.nav-links,
.nav-links-first {
    color: var(--primary-color);
    display: flex;
    text-decoration: none;
    padding: 0.6rem 0;
    font-size: 1.2rem;
    height: 44px;
}
.nav-links-first {
    border-right: none;
    padding-left: 0;
}
.nav-links {
    border-right: solid 1px;
    justify-content: center;
    opacity: 0.8;
}
.nav-item:first-child .nav-links {
    border-left: solid 1px;
}
.nav-links:hover {
    transition: all 0.2s ease-in-out;
    opacity: 1;
}
.nav-links.active {
    opacity: 1;
}
.hamburger {
    width: 23px;
    height: 23px;
    stroke: var(--primary-color);
}
.hamburger__bar--top,
.hamburger__bar--mid,
.hamburger__bar--bot {
    transform-origin: initial;
    stroke-width: 1px;
    transition: all 1s ease-in-out;
}
.hamburger__bar--top {
    transform: translate(0, -8px);
}
.hamburger__bar--mid {
    transform: translate(0, 0);
}
.hamburger__bar--bot {
    transform: translate(0, 8px);
}
.is-opened .hamburger__bar--top {
    transform: rotate(90deg) scaleX(0.8) translate(-11px, 0);
}
.is-opened .hamburger__bar--mid {
    transform: translate(11px, 0);
}
.is-opened .hamburger__bar--bot {
    transform: rotate(-45deg) scaleX(0.5) translate(-11px, 0);
}
.nav-links-mobile {
    display: none;
}
.menu-icon {
    display: none;
}
@media screen and (max-width: 1200px) {
    .nav-logo {
        left: 0px;
        display: grid;
        justify-content: center;
        width: 100vw;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 40vh;
        position: absolute;
        right: 100%;
        opacity: 1;
        justify-content: flex-start;
        gap: 1rem;
        margin-left: 0;
        opacity: 0;
        transition: opacity 250ms ease;
    }
    .nav-menu.active {
        opacity: 1;
        background-color: var(--active-menu-color);
        color: var(--primary-color);
        left: 0;
        top: 32px;
        height: 100vh;
    }
    .nav-links {
        justify-content: center;
        width: 100vw;
        padding-left: 0;
        border: none;
    }
    .nav-item:first-child .nav-links {
        border-left: none;
    }
    .nav-logo .nav-links {
        width: 0;
    }
    ul.nav-menu.active {
        padding: 20px 0;
    }
    .navbar-logo img {
        margin-left: 0px;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        margin-left: 20px;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: var(--background-color);
        border: 1px solid var(--background-color);
    }
    .nav-links-mobile:hover {
        background: var(--background-color);
        transition: 250ms;
    }
}
