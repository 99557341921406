.swiper {
    width: 100%;
    height: 100%;
}
.swiper-button-prev,
.swiper-button-next {
    opacity: 0 !important;
    height: 100% !important;
    width: 30% !important;
    top: 0 !important;
    position: absolute !important;
}
.swiper-button-prev:hover {
    cursor: w-resize;
}
.swiper-button-next:hover {
    cursor: e-resize;
}
.carousel-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
}
.carousel-container img,
.carousel-container video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.carousel-caption {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 5px 0;
    color: var(--primary-color);
    text-align: left;
    background-color: var(--background-color);
}
p.carousel-caption {
    font-size: 10px;
}
