.atelier-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 40px;
}
.atelier-container,
.residences-container {
    height: 100vh;
    overflow: scroll;
    padding: 0 20px;
}
.atelier-container {
    border-left: 1px solid var(--primary-color);
}
.published {
    opacity: 0.5;
}
h3.read-more-text {
    font-family: "Graphik";
}
h3.residences-item--title,
h3.atelier-item--title {
    font-family: "Graphikmedium";
}
.atelier-item,
.residences-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px;
    overflow-x: hidden;
}
.atelier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color);
    border-bottom: solid 1px;
    z-index: 0;
}
.atelier-section h1 {
    padding-top: 10px;
}
button.read-more-btn {
    border: none;
    cursor: none;
    background-color: transparent;
    padding: 0 10px;
    font-size: 0.8rem;
    opacity: 0.5;
}
button.read-more-btn:hover {
    opacity: 1;
    transition: all 0.25s;
}

@media screen and (max-width: 1000px) {
    .atelier-header {
        z-index: 0;
    }
    .atelier-section {
        grid-template-columns: 1fr;
    }
    .atelier-item,
    .residences-item {
        padding-bottom: 30px;
    }
    .atelier-container,
    .residences-container {
        height: auto;
        overflow: auto;
        border-left: none;
        padding: 0 20px 20px 20px;
    }
    .residences-container {
        height: auto;
        overflow: auto;
    }
    .atelier-section h1 {
        padding: 10px 0 0 0;
        font-size: 3em;
    }
}
