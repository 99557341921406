.contact-pic {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    opacity: 0.9;
    transition: all 0.2s ease-in-out;
}

.img-module-contact {
    margin-top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
}
.contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 20px 60px;
    gap: 20px;
}
.privacy-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 65px 20px 60px;
    gap: 20px;
}
.contact-grid:nth-of-type(1) {
    padding: 65px 20px 0;
}

.category-title {
    padding: 30px 40px 0px 40px;
}

img.contact-pic:hover {
    opacity: 1;
}

@media screen and (max-width: 1200px) {
    .privacy-grid {
        grid-template-columns: 1fr;
    }
    .contact-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    .contact-grid {
        grid-template-columns: 1fr;
    }
}
