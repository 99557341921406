@font-face {
    font-family: "Graphikmedium";
    src: url("../public/Graphik-Medium-Web.woff2") format("woff2"),
        url("../public/Graphik-Medium-Web.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../public/Graphik-Regular-Web.woff2") format("woff2"),
        url("../public/Graphik-Regular-Web.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
::-webkit-scrollbar {
    display: none;
}
:root {
    --primary-color: #006eff;
    --secondary-color: #006eff;
    --background-color: rgb(255, 255, 255);
    --active-menu-color: rgba(255, 255, 255, 0.8);
}
::selection {
    background-color: transparent;
}
.darkmode {
    --primary-color: #e2e2e2;
    --secondary-color: rgb(0, 0, 0);
    --background-color: rgb(39, 39, 39);
    --active-menu-color: rgba(39, 39, 39, 0.8);
}
button#toggleMode {
    top: 8px;
    right: 10px;
    position: absolute;
    background: none;
    border: none;
    cursor: none;
}
#toggleMode img {
    width: 27px;
}

* {
    font-feature-settings: "liga", "kern";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: none;
    -ms-overflow-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: none;
    color: var(--primary-color);
}

main {
    font-family: "Graphik", Fallback, sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--background-color);
}

h3 {
    font-size: 1.2rem;
}
p {
    line-height: 1.2rem;
}

a {
    color: var(--primary-color);
}

a:hover {
    text-decoration: none;
    color: var(--primary-color);
}

.hand {
    stroke: #006eff;
    stroke-width: 2;
    stroke-linecap: round;
}

#canvas {
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
    pointer-events: none;
    z-index: 10;
}

body.darkmode #canvas {
    mix-blend-mode: plus-lighter;
}
body:has(.home) #canvas {
    mix-blend-mode: exclusion;
}
.policy h1 {
    text-align: left;
}

footer {
    margin-top: auto;
    z-index: 0;
}
@media screen and (max-width: 1200px) {
    h3 {
        font-family: "Graphikmedium";
        font-size: 1rem;
        line-height: 1.4rem;
    }
    p {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}
