.residences-pic {
    width: 100%;
    object-fit: cover;
}
img.residences-pic {
    aspect-ratio: 16/9;
    opacity: 0.9;
    transition: all 0.2s ease-in-out;
}
img.residences-pic:hover {
    opacity: 1;
}
.events-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 65px 20px 80px 20px;
    gap: 20px;
}
.events-grid {
    border-bottom: solid 1px;
    padding-bottom: 10px;
}
.events-item {
    padding: 40px 0px 80px 0px;
}
.events-item__text p {
    margin-bottom: 10px;
    max-width: 425px;
}
.events-item-section {
    display: grid;
    gap: 10px;
}
.events-item__image {
    width: 100%;
    object-fit: cover;
}
img.events-item__image {
    aspect-ratio: 16/4;
}
.events-item__container {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 20px;
    gap: 20px;
}
.events-item__info {
    display: grid;
    overflow-wrap: anywhere;
}
.title-text {
    padding-top: 1.25rem;
    margin-bottom: 0;
    font-family: "Graphikmedium", Fallback, sans-serif;
}
.description-text {
    padding-bottom: 10px;
}
.category-title {
    padding: 30px 40px 0px 40px;
}

@media screen and (max-width: 1200px) {
    img.events-item__image {
        aspect-ratio: 16/6;
    }
    img.residences-pic {
        opacity: 1;
    }
    .events-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .events-grid {
        gap: 30px;
    }
    .events-item__text {
        grid-column: span 2;
    }
}
@media screen and (max-width: 1000px) {
    .events-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 700px) {
    .events-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .events-item__container {
        grid-template-columns: repeat(1, 1fr);
    }
    .events-item__btn {
        order: -1;
    }
}
