a {
    font-size: 0.8rem;
}
.content__gdprLegal,
.content__gdprLegal a {
    font-size: 0.5rem;
    line-height: 1;
    width: 270px;
}
.contact a {
    text-decoration: none;
}
.footer-container {
    padding: 1.5rem 20px 0.5rem 20px;
    display: flex;
    flex-direction: row;
    background-color: var(--background-color);
    border-top: 1px solid var(--primary-color);
    margin-top: auto;
}
.footer-subscription {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-content: flex-start;
    color: var(--primary-color);
    width: 50em;
    max-width: 500px;
    gap: 2px;
    padding-top: 1px;
}

input {
    padding: 2px 15px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 0.8rem;
    margin-bottom: 3px;
    border: 1px solid var(--primary-color);
    background-color: var(--background-color);
}
input::placeholder {
    color: var(--primary-color);
    opacity: 0.4;
}
footer button {
    cursor: none !important;
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    transition: all 0.2s ease-out;
    padding: 2px 15px;
    font-size: 0.8rem;
}
footer button:hover {
    color: var(--background-color);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
.footer-links {
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}
.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px auto 0 auto;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}
p {
    color: var(--primary-color);
    font-size: 0.8rem;
    margin-bottom: 0;
}
.footer-link-items a {
    text-decoration: none;
}
.footer-link-item.active {
    opacity: 1;
}
.footer-link-item {
    opacity: 0.6;
}
.footer-link-item:hover {
    transition: 0.3s ease-out;
    opacity: 1;
}
.contact {
    width: 100%;
    text-align: right;
}
.contact-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 0 0 0 auto;
}
.website-rights {
    color: var(--primary-color);
    margin-bottom: 16px;
    margin-right: 40px;
    line-height: 1.5;
}
.logo-meta {
    width: 80px;
    margin-top: 5px;
}

@media screen and (max-width: 1200px) {
    a {
        font-size: 1rem;
    }
    .contact-wrap {
        justify-content: center;
        width: 100%;
        max-width: 100%;
        margin: 0 0 0 0;
        align-items: center;
    }
    .footer-container {
        padding: 2rem 0 2rem 0px;
        flex-direction: column-reverse;
        align-items: center;
    }
    .footer-subscription {
        justify-content: center;
        flex-direction: column;
        align-content: space-around;
        width: 100%;
    }
    .footer-links {
        display: none;
    }
    .footer-input {
        width: 100%;
    }
    .footer-link-wrapper {
        display: none;
    }
    .website-rights {
        text-align: center;
        margin-right: 0;
    }
}
